import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ParamsModel } from '@app/models/params-model';
import {
  HttpParams,
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { saveAs } from 'file-saver';
import { DateTime, Settings } from 'luxon';
import { HttpService } from '@zonar-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '../i18n/translations.service';
import { filter, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { fileNamePrefix } from '@src/app/shared/consts/csv.const';
import { ZonarUIBaseCsvExportService } from '@zonar-ui/table';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService extends ZonarUIBaseCsvExportService<
  HttpResponse<Blob>
> {
  private queryParams: Subscription;
  private apiUrl = environment.apiBase.url;
  private assetsUrlBase = `${this.apiUrl}/assets/`;
  private scansUrlBase = `${this.apiUrl}/scans/`;
  private readonly pageSize = 500;
  private onDestroy$ = new Subject<void>();

  public options: any;
  public urlParams: any;
  public companyId: any;
  public pageTitle: string;
  public currentTabRoute: string;
  navLinks = [];

  public idleHeaderData = [];

  public csvConfig;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    public translations: Translations
  ) {
    super();
    this.route.queryParams.subscribe((params) => {
      this.fileName = this.getFileName();
      this.serviceCallObservable = this.callUrlForCompanyId({
        ...params,
        timezone: Settings.defaultZone.name,
        csv_filename: `${this.fileName}.csv`
      });
    });
  }

  subscribeForRouteChanges() {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router),
        tap((event: NavigationEnd) => {
          const rawTabRoute = event.url.split('/').at(-1);
          this.currentTabRoute = rawTabRoute.split('?')[0];
        })
      )
      .subscribe();
  }

  callUrlForCompanyId(params: ParamsModel): Observable<HttpResponse<Blob>> {
    this.subscribeForRouteChanges();
    const tabRoute = this.currentTabRoute;
    const urlBase =
      tabRoute === 'event' ? this.scansUrlBase : this.assetsUrlBase;
    return this.callHttpService(`${urlBase}${params.company_id}`, params);
  }

  callHttpService(
    url: string,
    params: ParamsModel
  ): Observable<HttpResponse<Blob>> {
    const callParams = this.createParams(params);
    const httpParams: HttpParams = new HttpParams({ fromObject: callParams });
    return this.http.get(url, {
      params: httpParams,
      observe: 'response',
      responseType: 'blob'
    });
  }

  createParams(selection: ParamsModel): Params {
    const queryParams: Params = {};
    Object.keys(selection).forEach((key) => {
      const value = selection[key];
      if (value) {
        queryParams[key] = value;
      }
    });
    // Removing unneeded parameters from call
    delete queryParams['companyId'];
    return queryParams;
  }

  getFileName(): string {
    const tabRoute = this.currentTabRoute;
    const prefix =
      tabRoute === 'event' ? fileNamePrefix.EVENTS : fileNamePrefix.ASSETS;
    const fileName = `${prefix}-${DateTime.now().toFormat(
      'yyyy-MM-dd_HH_mm_ss'
    )}`;
    return fileName;
  }

  getAssetList(): Observable<any> {
    return new Observable((observer) => {
      this.route.queryParams.pipe(take(1)).subscribe((params) => {
        const fileName = `${fileNamePrefix.ASSETS}-${DateTime.now().toFormat(
          'yyyy-MM-dd_HH_mm_ss'
        )}.csv`;
        const url = `${this.assetsUrlBase}${params.company_id}`;
        this.callHttpService(url, {
          csv_filename: fileName,
          all_assets_csv_flag: true
        }).subscribe(
          (data) => {
            if (data.status === 200 && data.body) {
              observer.next({
                data: data.body,
                fileName
              });
            } else {
              observer.next(null);
            }
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
      });
    });
  }
}
